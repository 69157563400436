<template>
  <div class="member_info_container">
    <MemberTitle :memberTitle="L['实名信息']"></MemberTitle>
    <div class="member_info">
      <table class="form_item">
        <tr>
          <td>
            <span class="tag_name"><b class="required">*</b>{{ L['姓名'] }}：</span>
          </td>
          <td>
            <el-input id="authName" v-model="authName" maxlength="10" show-word-limit>
            </el-input>
          </td>
        </tr>
        <tr>
          <td>
            <span class="tag_name"><b class="required">*</b>{{ L['身份证号'] }}：</span>
          </td>
          <td>
            <el-input id="authNumber" v-model="authNumber" maxlength="18" show-word-limit>
            </el-input>
          </td>
        </tr>
        <tr>
          <td>
            <span class="tag_name">{{ L['身份证人像面'] }}：</span>
          </td>
          <td>
            <form action="" enctype="multipart/form-data">
              <label for="file" class="chooseBtn card">
                <img v-if="authPic1Path" :src="authPic1Path" id="show" />
                <span v-else class="choose_plus">+</span>
              </label>
              <input id="file" class="filepath" @change="changepic" type="file" name="image" size="1"
                accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp" />
            </form>
          </td>
        </tr>
        <tr>
          <td>
            <span class="tag_name">{{ L['身份证国徽面'] }}：</span>
          </td>
          <td>
            <form action="" enctype="multipart/form-data">
              <label for="file2" class="chooseBtn card">
                <img :src="authPic2Path" id="show2" v-if="authPic2Path" />
                <span v-else class="choose_plus">+</span>
              </label>
              <input id="file2" class="filepath" @change="changepic2" type="file" name="image" size="1"
                accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp" />
            </form>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            {{
                L['照片仅支持JPG、GIF、PNG、JPEG、BMP格式;文件大小请在4.0MB之内。']
            }}
          </td>
        </tr>
      </table>

      <div class="memInfo_save">
        <el-button @click="memberInfoSave">{{ L['保存'] }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { ElInput, ElButton, ElMessage } from 'element-plus'
import { getCurrentInstance, ref, onMounted, watch } from 'vue'
import MemberTitle from '../../components/MemberTitle'
import { apiUrl } from '../../utils/config'

import axios from 'axios'
export default {
  name: 'MemberInfo',
  components: {
    ElInput,
    ElButton,
    MemberTitle
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const L = proxy.$getCurLanguage()
    const authName = ref('') //姓名
    const authNumber = ref('') //身份证号
    const authPic1 = ref('') //正面
    const authPic1Path = ref('') //正面图片
    const authPic2 = ref('') //反面
    const authPic2Path = ref('') //反面图片

    const changepic = e => {
      //上传头像
      let newsrc = getObjectURL(e.target.files[0])
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('source', 'headImg')
      let config = {
        headers: { 
          'Content-Type': 'multipart/form-data',
          'Authorization':'Bearer '+ localStorage.getItem('access_token')
         }
      }
      let url = apiUrl + 'v3/oss/front/upload'
      if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
        authPic1Path.value = newsrc
        axios.post(url, data, config).then(res => {
          if (res.status === 200) {
            ElMessage.success(L['上传图片成功'])
            authPic1Path.value = res.data.data.url
            authPic1.value = res.data.data.path
          } else {
            ElMessage.warning(L['上传图片失败'])
          }
        })
      } else {
        ElMessage.warning(L['图像应小于4MB'])
        // alert("待处理")
        // authPic1.value = authPic1.value
      }
    }
    const changepic2 = e => {
      //上传头像
      let newsrc = getObjectURL(e.target.files[0])
      let data = new FormData()
      data.append('file', e.target.files[0])
      data.append('source', 'headImg')
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization':'Bearer '+ localStorage.getItem('access_token')
        }
      }
      let url = apiUrl + 'v3/oss/front/upload'
      if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
        authPic2Path.value = newsrc
        axios.post(url, data, config).then(res => {
          if (res.status === 200) {
            ElMessage.success(L['上传图片成功'])
            authPic2Path.value = res.data.data.url
            authPic2.value = res.data.data.path
          } else {
            ElMessage.warning(L['上传图片失败'])
          }
        })
      } else {
        ElMessage.warning(L['图像应小于4MB'])
        // alert("待处理")
        // authPic1.value = authPic1.value
      }
    }
    const getObjectURL = file => {
      //createObjectURL在前端显示更换后的头像
      var url = null
      if (window.createObjectURL != undefined) {
        // basic
        url = window.createObjectURL(file)
      } else if (window.URL != undefined) {
        // mozilla(firefox)
        url = window.URL.createObjectURL(file)
      } else if (window.webkitURL != undefined) {
        // webkit or chrome
        url = window.webkitURL.createObjectURL(file)
      }
      return url
    }

    const getInitData = () => {
      //初始化数据
      proxy.$get('v3/member/front/member/memberInfo').then(res => {
        if (res.state == 200) {
          authName.value = res.data.authName
          authNumber.value = res.data.authNumber
          authPic1Path.value = res.data.authPic1
          authPic2Path.value = res.data.authPic2
        }
      })
    }

    const memberInfoSave = () => {
      //保存
      // let params = new FormData()
      let params = {
        authName: authName.value,
        authNumber: authNumber.value,
        authPic1: authPic1.value,
        authPic2: authPic2.value
      }

      if(!authPic1.value){
        delete params.authPic1
      }
      if(!authPic2.value){
        delete params.authPic2
      }

      let reg = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/im

      if ( reg.test(authName.value)) {
        ElMessage.warning('真实姓名包含特殊字符，请重新输入')
        return
      }
      if (authName.value != undefined && authName.value != null) {
        params.authName = authName.value.trim()
      }

      if (authNumber.value != undefined && authNumber.value != null) {
        params.authNumber = authNumber.value.trim()
      }


      proxy.$post('v3/member/front/member/updateInfo', params).then(res => {
        if (res.state == 200) {
          ElMessage.success(res.msg)
          getInitData()
        } else {
          ElMessage.warning(res.msg)
        }
      })
    }
    onMounted(() => {
      getInitData()
    })
    return {
      L,
      authName,
      authNumber,
      authPic1,
      authPic1Path,
      authPic2,
      authPic2Path,
      memberInfoSave,
      changepic,
      changepic2,
    }
  }
}
</script>
<style lang="scss">
@import '../../style/member/info.scss';

.el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 302px;
}

.el-radio-button__inner,
.el-radio-group {
  display: flex;
}
  b.required{
    color: red;
    font-weight: normal;
  }
  span.choose_plus{
    border:1px dashed #dcdcdc;
    padding: 12px 15px;
    color: #ddd;
    cursor: pointer;
  }
  .member_info_container .member_info .card img {
    border-radius: 0;
  }
</style>
